import { http } from "@/utils/http";
export default {
    login(params: object) {
        // 登录
        return http({
            url:`/members/api/v1/login`,
            method:'post',
            data:params
        })
    },
     getCode(params: string | number){
         // 获取短信验证码
         return http({
             url:`/members/api/v1/code/${params}`,
             method:'get',
         })
     },
    loginByPhone(params: object){
        // 手机号登录
        return http({
            url:'/oauth/mobile',
            method:'post',
            params:params
        })
    },
    register(params: object){
        // 注册
        return http({
            url:`/members/api/v1/register`,
            method:'post',
            data:params
        })
    },
    upload(params: object){
        // 上传
        return http({
            url:`/api/v1/attachment`,
            method:'post',
            data:params,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    mobileLogin(params: object){
        // 手机号登录
        return http({
            url:`/oauth/mobile`,
            method:'post',
            params:params,
            headers: {
                'no-auth': true
            }
        })
    },
    resetPsw(params: object){
        // 重置密码
        return http({
            url:`/members/api/v1/reset_password_phone`,
            method:'put',
            data:params
        })
    },
    getChina(){
        // 获取全国地区id
        return http({
            url:`/api/v1/china`,
            method:'get'
        })
    }
}