























































import { Vue, Provide, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/login'
import { Toast } from "vant";
@Component({
  components: {
    Nav,
  },
})
export default class ForgetPsw extends Vue {
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";

  async sendSms() {
    if (this.mobile) {
      const reg = /^1[3-9]\d{9}$/;
      if (reg.test(this.mobile)) {
        const res = await serve.getCode(this.mobile)
        if(res.data.code === 0){
          Toast('验证码已发送，请注意查收')
          this.sendLoading = true;
          this.cutTime();
        }
      } else {
        Toast("手机号格式错误");
      }
    } else {
      Toast("请输入手机号");
    }
  }

  async submit () {
    const data = {
      password:this.password,
      code:this.code,
      phone:this.mobile
    }
    if(this.password!=this.newpassword){
      Toast.fail('两次密码不一致')
    }else {
      const res = await serve.resetPsw(data)
      if(res.data.code === 0){
        Toast.success('重置密码成功')
        this.$router.push('/')
      }else {
        Toast.fail(res.data.message)
      }
    }
  }

  private goBack(): void{
      this.$router.push('/login')
  }

  private cutTime(): void {
    const timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(timer);
        this.time = 60;
        this.sendLoading = false;
      }
    }, 1000);
  }
}
