import { render, staticRenderFns } from "./forgetPsw.vue?vue&type=template&id=34b86816&scoped=true&"
import script from "./forgetPsw.vue?vue&type=script&lang=ts&"
export * from "./forgetPsw.vue?vue&type=script&lang=ts&"
import style0 from "./forgetPsw.vue?vue&type=style&index=0&id=34b86816&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b86816",
  null
  
)

export default component.exports